import classNames from "classnames";
import {ReactNode, memo} from "react";

// props
type Props = {
	className?: string;
	children: ReactNode;
};

const Row = memo(({className, children}: Props) => {
	const classes = classNames("row", className);
	return <div className={classes}>{children}</div>;
});

export default Row;
