import { Suspense, useEffect } from "react";
import Loader from "../components/Loader";
import { useAuth } from "../context/Auth";
import AppRoutes from "../routers";

function App() {
	const { checkUserIsLoggedIn } = useAuth();

	useEffect(() => {
		checkUserIsLoggedIn();
	}, [checkUserIsLoggedIn]);

	console.log("App");
	return (
		<Suspense fallback={<Loader />}>
			<AppRoutes />
		</Suspense>
	);
}

export default App;
