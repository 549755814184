import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import App from "./App/App";
import AuthProvider from "./context/Auth";
import "./index.scss";
import "./lib/Icons";

// container
const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

// render app
root.render(
	<AuthProvider>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</AuthProvider>
);
