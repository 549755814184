import React, {memo} from "react";
import Icon from "./UI/Icon";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

/**
 * Address component
 */
interface IAddress {
	icon: IconProp | string;
	text: string;
}
const Address: React.FC<{address: IAddress[]}> = memo(({address}) => {
	return (
		<address>
			<ul>
				{address.map((address, index) => (
					<li key={index}>
						<Icon icon={address.icon as IconProp} />
						<span>{address.text}</span>
					</li>
				))}
			</ul>
		</address>
	);
});

export default Address;
