import { memo } from "react";
import { Props } from "../../../utils";
import DropdownProvider from "../../../context/Dropdown";
import Button from "./DropdownButton";
import Menu from "./DropdownMenu";
import Item from "./DropdownItem";

/**
 * Dropdown component
 */

type DropdownProps = Props & {
	as?: string;
};
const Dropdown: React.FC<DropdownProps> = memo((props) => {
	const { children } = props;
	return <DropdownProvider {...props}>{children}</DropdownProvider>;
});

Dropdown.defaultProps = {
	as: "div",
};

export default Object.assign(Dropdown, {
	Button,
	Menu,
	Item,
});
