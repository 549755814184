import axios from "axios";
import {API_URL_BASE} from "./variable.services";

const BASE_URL = API_URL_BASE + "/user/auth";

export interface IUser {
	first_name: string;
	last_name: string;
	username: string;
	email: string;
	photo_url: string;
	mobile: string;
	role: string;
	active: boolean;
	country: string;
	timezone: string;
}
class AuthService {
	info() {
		return {
			first_name: "",
			last_name: "",
			username: "",
			email: "",
			mobile: "",
			role: "",
			country: "",
			timezone: "",
			photo_url: "",
			active: false,
		};
	}

	signin(token: string) {
		return axios.post(BASE_URL + "/google", {firebaseToken: token});
	}

	signinWithEmail(body: IUser, token: string) {
		return axios.post(BASE_URL, {...body, firebaseToken: token});
	}

	signup(body: IUser, token: string) {
		return axios.post(BASE_URL, {...body, firebaseToken: token});
	}
}

const authService = new AuthService();
export {authService};
