import { memo } from "react";
import Logo from "../layout/Logo";

const Loader = memo(() => {
	return (
		<div className="loader">
			<div className="loader-center">
				<Logo mode="dark" />
				<h1>Loading...</h1>
			</div>
		</div>
	);
});

export default Loader;
