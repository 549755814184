import { IconDefinition, library } from "@fortawesome/fontawesome-svg-core";
import {
	faFacebook,
	faGithub,
	faGoogle,
	faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import {
	faCaretDown,
	faCaretLeft,
	faCaretRight,
	faCaretUp,
	faCog,
	faHome,
	faMapMarked,
	faMobile,
	faSignIn,
	faSignOut,
	faSpinner,
	faUser,
} from "@fortawesome/free-solid-svg-icons";

// add icon
library.add(
	faUser,
	faCog,
	faSignOut,
	faCaretRight,
	faCaretDown,
	faCaretLeft,
	faCaretUp,
	faSignIn,
	faHome,
	faSpinner,
	faMobile,
	faCaretRight,
	faMapMarked,
	faInstagram as IconDefinition,
	faGoogle as IconDefinition,
	faGithub as IconDefinition,
	faFacebook as IconDefinition
);
