import classNames from "classnames";
import Typography from "../components/UI/Typography";
import { memo } from "react";
import { Link } from "react-router-dom";
import { AppContent, Sizes } from "../utils";

/**
 * Logo components
 */
type mode = "dark" | "light";
type LogoProps = {
	mode?: mode;
	size?: Sizes;
};
enum Mode {
	light = "light",
	dark = "dark",
}

const LogoIcon: React.FC<LogoProps> = memo(({ mode, size }) => {
	const classes = classNames("brand-icon", Mode[mode!], size);
	return (
		<div className={classes}>
			<Typography variant="inherit">fw</Typography>
		</div>
	);
});

LogoIcon.defaultProps = {
	mode: "light",
	size: "sm",
};

type LogoNameProps = LogoProps & {
	className?: string;
};

const LogoName: React.FC<LogoNameProps> = memo(({ className, mode, size }) => {
	const classes = classNames("fw-name", Mode[mode!], size, className);
	return (
		<div className={classes}>
			{AppContent.fontendText}
			<span className="secondary">{AppContent.webText}</span>
		</div>
	);
});

LogoName.defaultProps = {
	mode: "light",
	size: "md",
};

const Logo: React.FC<LogoNameProps> = memo(({ mode, size }) => {
	return (
		<>
			<Link to="/" className="navbar-brand">
				<LogoIcon size={size} mode={mode} />
				<LogoName size={size} mode={mode} />
			</Link>
			<button
				className="navbar-toggler"
				type="button"
				data-bs-toggle="collapse"
				data-bs-target="#navbarSupportedContent"
				aria-controls="navbarSupportedContent"
				aria-expanded="false"
				aria-label="Toggle navigation">
				<span className="navbar-toggler-icon"></span>
			</button>
		</>
	);
});

export default Object.assign(Logo, {
	LogoIcon,
	LogoName,
});
