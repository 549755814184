import React, { ReactNode, memo, forwardRef } from "react";

declare module "react" {
	function forwardRef<T, P = {}>(
		render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
	): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}
type ComponentProps = {
	children: ReactNode;
	className?: string;
	style?: object;
	as: string;
} & React.RefAttributes<HTMLDivElement>;

const Component: React.FC<ComponentProps> = forwardRef<
	HTMLDivElement,
	ComponentProps
>((props, ref: any) => {
	const { children, className, as, style } = props;
	return React.createElement(as, { className, style, ref }, children);
});
export default memo(Component);
