import classNames from "classnames";
import {Sizes} from "../../utils";
import avatar from "../../assets/images/avatar.png";

type Props = {
	src?: string;
	alt?: string;
	size?: Sizes;
	className?: string;
};

const Avatar: React.FC<Props> = ({size, src, alt, ...rest}) => {
	const classes = classNames({
		avatar: true,
		["" + size]: size,
	});
	return (
		<div className={classes}>
			<img src={src} alt={alt} />
		</div>
	);
};

Avatar.defaultProps = {
	src: avatar,
};
export default Avatar;
