import { memo } from "react";
import { AppRoute } from "../../utils";
import Avatar from "../UI/Avatar";
import Dropdown from "../UI/Dropdown";
import Typography from "../UI/Typography";

/**
 * User control
 */
const UserTopbarControl = memo(() => {
	return (
		<Dropdown as="li" className="nav-item">
			<Dropdown.Button>
				<div className="d-flex align-items-center">
					<Avatar size="xs" />
					<Typography className="mb-0 ms-2 text-light xs" variant="body1">
						PK
					</Typography>
				</div>
			</Dropdown.Button>
			<Dropdown.Menu align="end">
				<Dropdown.Item link to={AppRoute.profile} icon="user">
					Profile
				</Dropdown.Item>
				<Dropdown.Item link to={AppRoute.settings} icon="cog">
					Settings
				</Dropdown.Item>
				<Dropdown.Item icon="sign-out">Logout</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);
});

export default UserTopbarControl;
