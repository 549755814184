import React from "react";
import { Props } from "../../utils/Types";

type HeadingVariant =
	| "h1"
	| "h2"
	| "h3"
	| "h4"
	| "h5"
	| "h6"
	| "subtitle1"
	| "subtitle2"
	| "body1"
	| "body2"
	| "inherit";

type hedingProps = Props & {
	variant?: HeadingVariant;
};

enum propsVariant {
	h1 = "h1",
	h2 = "h2",
	h3 = "h3",
	h4 = "h4",
	h5 = "h5",
	h6 = "h6",
	subtitle1 = "h6",
	subtitle2 = "h6",
	body1 = "p",
	body2 = "p",
	inherit = "span",
}

const Typography: React.FC<hedingProps> = ({
	variant,
	children,
	className,
}) => {
	return React.createElement(propsVariant[variant!], { className }, children);
};

Typography.defaultProps = {
	variant: "h1",
};

export default Typography;
