import { memo } from "react";
import Container from "../components/UI/Container";
import Logo from "./Logo";
import Navbar from "./Navbar";

/**
 * Header component
 */
const Header: React.FC = memo(() => {
	return (
		<header className="navbar navbar-expand-md fixed-top bg-primary">
			<Container>
				<Logo size="sm" />
				<Navbar />
			</Container>
		</header>
	);
});

export default Header;
