import { memo } from "react";
import AppLink from "../components/AppLink";
import NavLink from "../components/AppLink";
import Icon from "../components/UI/Icon";
import UserTopbarControl from "../components/widgets/UserTopbarControl";
import { useAuth } from "../context/Auth";
import { AppContent, AppRoute } from "../utils";

/**
 * Navbar component
 */
const Navbar = memo(() => {
	const { state } = useAuth();
	return (
		<nav className="collapse navbar-collapse">
			<ul className="navbar-nav ms-auto mb-2 mb-lg-0">
				<AppLink to={AppRoute.portfolio} menu>
					{AppContent.profile}
				</AppLink>
				<AppLink to={AppRoute.blogs} menu>
					{AppContent.blogs}
				</AppLink>
				{state.isAuth ? (
					<UserTopbarControl />
				) : (
					<li className="nav-item">
						<NavLink className="nav-link" to={AppRoute.signin}>
							<Icon icon="sign-in" /> Sign in
						</NavLink>
					</li>
				)}
			</ul>
		</nav>
	);
});

export default Navbar;
