import classNames from "classnames";
import { memo } from "react";
import { useDropdown } from "../../../context/Dropdown";
import { Props } from "../../../utils";

/**
 * Dropdown menu
 */

type Align = "end" | "start";
type DropdownMenuProps = Props & {
	align?: Align;
};
const DropdownMenu: React.FC<DropdownMenuProps> = memo(
	({ className, children, align, ...rest }) => {
		const { show } = useDropdown();
		const classes = classNames(
			"dropdown-menu",
			align && "dropdown-menu-" + align,
			className,
			show && "show"
		);
		return <div className={classes}>{children}</div>;
	}
);

export default DropdownMenu;
