import classNames from "classnames";
import {ReactNode, memo} from "react";

type Props = {
	children: ReactNode;
	className?: string;
	xs?: number;
	sm?: number;
	md?: number;
	lg?: number;
	xl?: number;
};

const Col: React.FC<Props> = memo(
	({children, xs, sm, md, lg, xl, className}) => {
		const isCol = xs === 0 && sm === 0 && md === 0 && lg === 0 && xl === 0;
		const classes = classNames({
			col: isCol,
			["col-xs-" + xs]: xs !== 0,
			["col-sm-" + sm]: sm !== 0,
			["col-md-" + md]: md !== 0,
			["col-lg-" + lg]: lg !== 0,
			["col-xl-" + xl]: xl !== 0,
			["" + className]: className,
		});

		return <div className={classes}>{children}</div>;
	}
);

Col.defaultProps = {
	xs: 0,
	sm: 0,
	md: 0,
	lg: 0,
	xl: 0,
};

export default Col;
