import { IconProp } from "@fortawesome/fontawesome-svg-core";
import classNames from "classnames";
import { memo } from "react";
import { Props } from "../../../utils";
import NavLink from "../../AppLink";
import Icon from "../Icon";

type DropdownItemProps = Props & {
	link?: boolean;
	to?: string | object;
	icon?: IconProp;
};

const DropdownMenuItem: React.FC<DropdownItemProps> = memo(
	({ children, className, link, icon, to, ...rest }) => {
		const classes = classNames("dropdown-item", className);
		return link ? (
			<NavLink className={classes} to={to!}>
				{icon && <Icon icon={icon} className="me-1" />}
				{children}
			</NavLink>
		) : (
			<div className={classes}>
				{icon && <Icon icon={icon} className="me-1" />} {children}
			</div>
		);
	}
);

export default DropdownMenuItem;
