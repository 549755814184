import {
	IconName,
	IconProp,
	IconLookup,
	SizeProp,
	IconDefinition,
} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {memo} from "react";

export enum IconVariant {
	solid = "colid",
	regular = "regular",
	brands = "brands",
}

type TIconVariant = "solid" | "regular" | "brands";

type Props = {
	icon: IconName | IconProp | IconLookup | IconDefinition;
	spin?: boolean;
	space?: string;
	size?: SizeProp;
	className?: string;
	variant?: TIconVariant;
};

/**
 * Icon
 */
const Icon: React.FC<Props> = memo(
	({variant, icon, className, size, space, spin, ...rest}) => {
		return variant === IconVariant.brands ? (
			<FontAwesomeIcon icon={icon} />
		) : (
			<FontAwesomeIcon
				size={size}
				spacing={space}
				icon={icon}
				spin={spin}
				className={className}
				{...rest}
			/>
		);
	}
);

Icon.defaultProps = {};
export default Icon;
