import { signInWithPopup, signOut } from "firebase/auth";
import { createContext, useCallback, useContext, useReducer } from "react";
import { auth, googleProvider } from "../firebase";
import { authService, IUser } from "../services/auth.services";
import { Auth } from "../store/Constants";
import { Props } from "../utils";

interface IAuth {
	loading?: boolean;
	user: null | IUser;
	token: null | string;
	isAuth?: boolean;
	errors?: null;
}
const initialState: IAuth = {
	loading: false,
	user: null,
	token: null,
	isAuth: false,
	errors: null,
};
type AuthProviderProps = Props & {};
type AuthAction =
	| { type: Auth.AUTH_LOADING; payload?: boolean }
	| { type: Auth.AUTH_SUCCESS; payload: { user: IUser; token: string } }
	| { type: Auth.AUTH_FAILURE; payload: string | null };

const defaultDispatch: React.Dispatch<AuthAction> = () => initialState; // we never actually use this
const AuthContext = createContext({
	state: initialState,
	dispatch: defaultDispatch,
	loginWithGoogle: () => {},
	logout: () => {},
	loginWithEmail: () => {},
	checkUserIsLoggedIn: () => {},
});

/**
 * Auth reducer
 * 1. Loading
 * 2. Success
 * 3. Error
 * @param state
 * @param action
 * @returns
 */
function reducer(state: IAuth, action: AuthAction) {
	const { type, payload } = action;
	switch (type) {
		case Auth.AUTH_LOADING:
			return {
				...state,
				loading: payload ?? true,
			};
		case Auth.AUTH_SUCCESS:
			return {
				...state,
				loading: false,
				token: payload.token,
				user: payload.user,
				isAuth: !!payload.token,
			};
		case Auth.AUTH_FAILURE:
			return {
				...state,
				error: payload,
			};
		default:
			return state;
	}
}

let clearTimer: any;
const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const authSuccess = (user: IUser, token: string) => {
		dispatch({ type: Auth.AUTH_SUCCESS, payload: { user, token } });
	};

	const checkAuthTimeout = useCallback((time: any) => {
		clearTimer = setTimeout(() => {
			logout();
		}, time);
	}, []);

	const checkUserIsLoggedIn = useCallback(() => {
		const user = JSON.parse(localStorage.getItem("user") as string) as IUser;
		const token = localStorage.getItem("accessToken")!;
		const expireTime = new Date(
			JSON.parse(localStorage.getItem("expireTime") as string)
		);
		if (!user || new Date() > expireTime) {
			logout();
		} else {
			authSuccess(user, token);
			checkAuthTimeout(expireTime.getTime() - new Date().getTime());
		}
	}, [checkAuthTimeout]);

	const loginWithGoogle = async () => {
		try {
			dispatch({ type: Auth.AUTH_LOADING, payload: true });
			const response = await signInWithPopup(auth, googleProvider);
			const firebasetoken = await response.user
				.getIdToken()
				.then((token) => token);

			const authResponse = await authService.signin(firebasetoken);
			if (authResponse.status === 200) {
				const { token, user, exp } = authResponse.data;

				const expireDate = new Date(exp);
				localStorage.setItem("user", JSON.stringify(user));
				localStorage.setItem("expireTime", JSON.stringify(expireDate));
				localStorage.setItem("accessToken", token);

				dispatch({ type: Auth.AUTH_LOADING, payload: false });
				authSuccess(user, token);
				checkAuthTimeout(expireDate.getTime() - new Date().getTime());
				checkUserIsLoggedIn();
			}
		} catch (error) {}
	};

	const logout = () => {
		localStorage.removeItem("user");
		localStorage.removeItem("expireTime");
		if (clearTimer) {
			clearTimeout(clearTimer);
		}
		signOut(auth)
			.then(() => {})
			.catch((error) => {
				console.log(error);
			});
	};

	const loginWithEmail = () => {};

	return (
		<AuthContext.Provider
			value={{
				state,
				dispatch,
				logout,
				loginWithEmail,
				loginWithGoogle,
				checkUserIsLoggedIn,
			}}>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => useContext(AuthContext);
export default AuthProvider;
