import classNames from "classnames";
import {ReactNode, memo} from "react";

type ContainerProps = {
	children: ReactNode;
	isFull?: boolean;
	className?: string;
};

/**
 * Container component
 */
const Container = memo(({children, isFull, className}: ContainerProps) => {
	const classes = classNames(
		isFull ? "container-fluid" : "container",
		className
	);

	return <div className={classes}>{children}</div>;
});

export default Container;
