import { IconProp } from "@fortawesome/fontawesome-svg-core";
import classNames from "classnames";
import { memo } from "react";
import { Drop, useDropdown } from "../../../context/Dropdown";
import { Props } from "../../../utils";
import Icon from "../Icon";

type DropdownButtonProps = Props & {
	title?: string;
	isButton?: boolean;
	drop?: Drop;
};

/**
 * Dropdown button
 */
const DropdownButton: React.FC<DropdownButtonProps> = memo(
	({ children, className, title, drop, custom, isButton, ...rest }) => {
		const { onToggle, show } = useDropdown();

		const icon = ("caret-" + (show ? "up" : drop)) as IconProp;
		const classes = classNames(!custom && "dropdown-button", className);

		return (
			<div className={classes} onClick={onToggle} {...rest}>
				{children}
				<Icon className="ms-2" icon={icon} />
			</div>
		);
	}
);

DropdownButton.defaultProps = {
	drop: "down",
};

export default DropdownButton;
