import classNames from "classnames";
import { Link, NavLink } from "react-router-dom";
import { Props } from "../utils";

type NavLinkProps = Props & {
	menu?: boolean;
	to: string | object;
};

/**
 * App link
 * @param param0
 * @returns
 */
const AppLink: React.FC<NavLinkProps> = ({
	menu,
	to,
	children,
	className,
	...rest
}) => {
	return menu ? (
		<li className="nav-item">
			<NavLink to={to} className={classNames("nav-link", className)} {...rest}>
				{children}
			</NavLink>
		</li>
	) : (
		<Link to={to} className={classNames("link", className)} {...rest}>
			{children}
		</Link>
	);
};

export default AppLink;
