import { useEffect } from "react";

const useOutsideClick = (ref: React.RefObject<HTMLElement>, fun: Function) => {
	useEffect(() => {
		function handleClickOutside(event: any) {
			if (ref.current && !ref.current.contains(event.target)) {
				fun();
			}
		}

		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, [ref, fun]);
};

export default useOutsideClick;
