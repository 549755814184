export const AppRoute = {
	root: "/",
	profile: "/profile",
	settings: "/settings",
	signin: "/auth",
	signup: "/auth/signup",
	about: "about-us",
	contact: "contact-us",
	blogs: "blogs",
	privacy: "privacy-policy",
	services: "services",
	portfolio: "portfolio",
	notfound: "not-found",
	term: "term-and-conditions",
};
