import { memo } from "react";
import Address from "../components/Address";
import NavLink from "../components/AppLink";
import Col from "../components/UI/Col";
import Container from "../components/UI/Container";
import Icon from "../components/UI/Icon";
import Row from "../components/UI/Row";
import Logo from "./Logo";
import { ADDRESS } from "../utils";
//import Social from "../components/Social";

/**
 * Footer component
 */
const Footer = memo(() => {
	return (
		<footer className="footer">
			<Container className="mb-5">
				<Row>
					<Col>
						<Logo size="sm" mode="dark" />
						<h5 className="mt-3">A better way to build.</h5>
						<div className="pe-4">
							<p>
								Lorem ipsum dolor sit amet consectetur adipisicing elit. Est
								voluptatum ratione dolores quasi illo iusto nesciunt numquam rem
								officiis eaque inventore, vel, amet, placeat debitis consequatur
								necessitatibus! Voluptas, voluptatum itaque.
							</p>
						</div>
					</Col>

					<Col>
						<h5>Company</h5>
						<div className="footer-menu">
							<NavLink to="/about-us">
								<Icon icon="caret-right" className="me-2" />
								About us
							</NavLink>
							<NavLink to="/contact-us">
								<Icon icon="caret-right" className="me-2" />
								Contact us
							</NavLink>
							<NavLink to="/blogs">
								<Icon icon="caret-right" className="me-2" />
								Blogs
							</NavLink>
						</div>
					</Col>
					<Col>
						<h5>Address</h5>
						<div className="footer-menu">
							<NavLink to="/privacy-policy">
								<Icon icon="caret-right" className="me-2" /> Privacy policy
							</NavLink>
							<NavLink to="/term-and-conditions">
								<Icon icon="caret-right" className="me-2" /> Terms &amp;
								Conditions
							</NavLink>
						</div>
					</Col>
					<Col>
						<h5>Contact</h5>
						<Address address={ADDRESS} />
						{/* <Social /> */}
					</Col>
				</Row>
			</Container>
			<Container className="border-top pt-4 ">
				<div className="p-sm mb-0  text-center xs">
					&copy; - {new Date().getFullYear()}, All rights reserved. Powered by
					<Logo.LogoName size="xs" mode="dark" className="ms-1" />
					.in
				</div>
			</Container>
		</footer>
	);
});

export default Footer;
