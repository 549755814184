import classNames from "classnames";
import React, { useContext, useMemo, useRef, useState } from "react";
import Component from "../components/UI/Component";
import useOutsideClick from "../hooks/useOutsideClick";
import { Props } from "../utils";

export type Drop = "up" | "down" | "left" | "right";
export type DropdownContextValue = {
	show: boolean;
	onToggle?: () => void;
	onClose?: () => void;
};

const DropdownContext = React.createContext<DropdownContextValue>({
	show: false,
});

const DropdownProvider: React.FC<Props & { as?: string }> = ({
	children,
	className,
	as,
}) => {
	const [open, setOpen] = useState(false);

	// class
	const classes = classNames("dropdown", className);

	// ref
	const wrapperRef = useRef<HTMLDivElement>(null);

	// handlers
	const onToggle = () => setOpen(!open);
	const onClose = () => setOpen(false);

	// values
	const contextValue = useMemo(() => {
		return {
			show: open,
		};
	}, [open]);

	useOutsideClick(wrapperRef, onClose);

	return (
		<DropdownContext.Provider
			value={{
				...contextValue,
				onClose,
				onToggle,
			}}>
			<Component ref={wrapperRef} as={as ?? "div"} className={classes}>
				{children}
			</Component>
		</DropdownContext.Provider>
	);
};

export const useDropdown = () => useContext(DropdownContext);
export default DropdownProvider;
