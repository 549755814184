import {lazy} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import Layout from "../layout";
import {AppRoute} from "../utils";

const Home = lazy(() => import("../pages/Home"));
const About = lazy(() => import("../pages/About"));
const Contact = lazy(() => import("../pages/Contact"));
const Signin = lazy(() => import("../auth/Login"));
const Signup = lazy(() => import("../auth/Signup"));
const Portfolio = lazy(() => import("../pages/Portfolio"));
const Services = lazy(() => import("../pages/Services"));
const NotFound = lazy(() => import("../pages/NotFound"));
const Term = lazy(() => import("../pages/TermAndCondition"));
const Blogs = lazy(() => import("../pages/Blogs"));
const PrivacyPolicy = lazy(() => import("../pages/PrivacyPolicy"));

/**
 * App Routes
 * @returns
 */
const AppRoutes: React.FC = () => {
	return (
		<Routes>
			<Route path={AppRoute.root} element={<Layout />}>
				<Route index element={<Home />} />
				<Route path={AppRoute.about} element={<About />} />
				<Route path={AppRoute.contact} element={<Contact />} />
				<Route path={AppRoute.portfolio} element={<Portfolio />} />
				<Route path={AppRoute.services} element={<Services />} />
				<Route path={AppRoute.blogs} element={<Blogs />} />
				<Route path={AppRoute.term} element={<Term />} />
				<Route path={AppRoute.privacy} element={<PrivacyPolicy />} />
				<Route path={AppRoute.notfound} element={<NotFound />} />
				<Route path={AppRoute.signin} element={<Signin />} />
				<Route path={AppRoute.signup} element={<Signup />} />
				<Route path="*" element={<Navigate to="not-found" />} />
			</Route>
		</Routes>
	);
};

export default AppRoutes;
