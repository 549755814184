export const AppContent = {
	fontendText: "frontend",
	webText: "web",
	saveText: "Save",
	addText: "Add",
	deleteText: "Delete",
	profile: "Profile",
	setting: "Setting",
	Logout: "Signout",
	Signin: "Signin",
	blogs: "Blogs",
};
export const ADDRESS = [
	{
		icon: "map-marked",
		text: "153 Williamson Plaza, Maggieberg",
	},
	{
		icon: "mobile",
		text: "0000-000-000",
	},
];
